<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/profile">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.about") }}</li>
      </ul>
    </div>
    <div class="main main_page">
      <div class="about">
        <div class="one">
          <img
            class="logo"
            :src="
              sysDataDictionaryWbObj.TXHQDZ?.dataContent +
              sysDataDictionaryWbObj.SJDLOGO?.dataContent
            "
          />
        </div>
        <div class="two">
          <template
            v-if="sysDataDictionaryWbObj.NATION?.dataContent === 'NIGERIA'"
          >
            <p>
              <router-link to="/about_three">
                <b>{{ t("common.title.aboutBusiness") }}</b>
                <i class="iconfont icon-you2"></i>
              </router-link>
            </p>
            <p>
              <router-link to="/about_two">
                <b>{{ t("common.title.aboutTeam") }}</b>
                <i class="iconfont icon-you2"></i>
              </router-link>
            </p>
            <p>
              <router-link to="/about_one">
                <b>{{ t("common.title.aboutCompany") }}</b>
                <i class="iconfont icon-you2"></i>
              </router-link>
            </p>
          </template>
          <p>
            <b>{{ t("extra.versionInfo") }}</b>
            <span>v2.0.1</span>
          </p>
        </div>

        <div class="three">
          <p>
            {{
              "Copyright © 2021 " + sysDataDictionaryWbObj?.WZMC?.dataContent
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import StaticImage from '@/components/StaticImage.vue';
import { useI18n } from "@/lang";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  // components: { StaticImage },
  setup() {
    const { t } = useI18n();
    return {
      t,
      sysDataDictionaryWbObj,
    };
  },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 33% !important;
  margin-left: 33.5%;
}
</style>